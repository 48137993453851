:root {
	--bg-color: #121212;
	--text-color: #ffffff;
	--accent-color: rgb(111, 0, 111);
	--secondary-color: rgba(255, 255, 255, 0.616);
}

.inner-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-height: 75vh;
	padding: 2rem;
	box-sizing: border-box;
}

header {
	margin-bottom: 2rem;
}

h1 {
	font-size: 2.5rem;
	color: var(--accent-color);
	text-shadow: 0 0 10px rgba(255, 255, 255, 0);
}

/* .player {
	margin-bottom: 2rem;
	width: 100%;
	max-width: 500px;
} */

audio {
	width: 100%;
	border-radius: 8px;
}

.actions {
	display: flex;
	justify-content: center;
	gap: 1rem;
}

.btn {
	padding: 0.8rem 1.5rem;
	border: none;
	border-radius: 25px;
	font-size: 1rem;
	cursor: pointer;
	transition: all 0.3s ease;
}

.btn-primary {
	border-style: double;
	border-color: var(--accent-color);
	background-color: var(--accent-color);
	color: var(--text-color);
}

.btn-secondary {
	background-color: transparent;
	border-style: double;
	border-color: rgb(111, 0, 111);
	color: var(--accent-color);
}

.btn:hover {
	transform: translateY(-2px);
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.btn-primary:hover {
	background-color: transparent;
	border-style: double;
	border-color: rgb(111, 0, 111);
	color: var(--accent-color);
}

.btn-secondary:hover {
	background-color: var(--accent-color);
	color: var(--text-color);
}

/* ... (previous CSS styles) ... */

.player {
	background-color: #acacac18;
	border-radius: 8px;
	padding: 20px;
	margin-bottom: 2rem;
	width: 100%;
	min-width: 350px;
	max-width: 419px;
}

.track-info {
	display: flex;
	align-items: center;
	margin-bottom: 15px;
}

.album-art {
	width: 60px;
	height: 60px;
	border-radius: 4px;
	margin-right: 15px;
}

.track-details {
	flex-grow: 1;
}

.track-title {
	font-size: 18px;
	margin: 0;
	color: #fff;
}

.track-artist {
	font-size: 14px;
	margin: 5px 0 0;
	color: #b3b3b3;
  text-align: unset;
}

audio {
	width: 100%;
	margin-top: 15px;
}

/* Styling the audio player */
audio::-webkit-media-controls-panel {
	background-color: rgb(82, 82, 82);
	color: white;
}

audio::-webkit-media-controls-current-time-display,
audio::-webkit-media-controls-time-remaining-display {
	color: #ffffff;
}

audio::-webkit-media-controls-play-button,
audio::-webkit-media-controls-mute-button {
	background-color: orangered;
	border-radius: 50%;
}

audio::-webkit-media-controls-volume-slider {
	background-color: orangered;
	border-radius: 25px;
	padding-left: 8px;
	padding-right: 8px;
}

audio::-webkit-media-controls-seek-back-button,
audio::-webkit-media-controls-seek-forward-button {
	display: none;
}

/* ... (rest of the CSS) ... */

@media (max-width: 600px) {
	h1 {
		font-size: 2rem;
	}

	.actions {
		flex-direction: column;
	}
}


/* remix*/


.remix-form {
	width: 100%;
	min-width: 450px;
	
}

.remix-form-group {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	margin-bottom: 2rem;
}

.remix-form label {
	color: #ffffff;
	text-align: left;
}

textarea {
	width: 100%;
	padding: 14px;
	background-color: #ffffff;
	color: #30313d;
	border: 1px solid #e6e6e6;
	border-radius: 5px;
	font-size: 16px;
	font-weight: 400;
	outline: none;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
		Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}