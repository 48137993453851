/* fonts */
@import url("https://fonts.googleapis.com/css2?family=Courgette&display=swap");

.payment-container {
	/* background-color: #2A2A2A; */
	display: flex;
	gap: 2rem;
	flex-wrap: wrap;
	justify-content: space-between;
	border-radius: 10px;
	box-shadow: 0 0 10px rgba(138, 43, 226, 0.2);
	padding: 30px;
	width: 100%;
	max-width: 1020px;
	margin: 4rem auto;
	font-family: "Arial", sans-serif;
}

.text-container {
	margin: 0 0 2rem;
}

.text-container,
form {
	width: 48%;
}

.logo-container {
	display: flex;
	gap: 0.7rem;
	align-items: center;
}

.logo-container img {
	width: 30px;
	height: 30px;
}

.logo-container p {
	font-weight: 800;
	font-size: 16px;
	color: #8a2be2;
	text-transform: uppercase;
	font-family: "Courgette", cursive;
}

.instruct-text {
	margin-block: 2rem;
}

.instruct-text h1 {
	color: #3b4151;
	font-size: 16px;
	font-weight: 500;
}

.instruct-text p {
	font-size: 26px;
	font-weight: 600;
	color: #3b4151;
	padding-top: 0.5rem;
}

.total-container {
	display: flex;
	gap: 2rem;
justify-content: space-between;
	margin: 2rem 0;
	padding: 1rem 0;
	border-top: 1px solid #e6e6e6;
	border-bottom: 1px solid #e6e6e6;
}


.total-container h5 {
	font-size: 18px;
	color: #3b4151;
}

.text-container h3 {
	font-weight: 400;
	font-size: 12px;
}


.details-card {
    margin: 3rem 0 1rem;
}

.payment-details {
	width: 100%;
	display: flex;
	justify-content: space-between;
	gap: 1rem;
}

.payment-details p {
	font-size: 18px;
	font-weight: 600;
	color: #3b4151;
}

.payment-text {
	display: flex;
	flex-direction: column;
	gap: 0.3rem;
}

.payment-text h5 {
	font-size: 16px;
	color: #3b4151;
	font-weight: 600;
}

.payment-text h6 {
	font-size: 14px;
	color: #3b4151;
	font-weight: 400;
}

.form-group {
	margin-bottom: 20px;
}

.form-row {
	display: flex;
	justify-content: space-between;
}

.half-width {
	width: 48%;
}

.StripeElement {
	margin-bottom: 1rem;
}

label {
	display: block;
	margin-bottom: 5px;
	color: #30313d;
	font-weight: 400;
	font-size: 14px;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
		Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

input {
	width: 100%;
	padding: 14px;
	background-color: #ffffff;
	color: #30313d;
	border: 1px solid #e6e6e6;
	border-radius: 5px;
	font-size: 16px;
	font-weight: 400;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
		Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

input:focus,
.p-Input--focused {
	outline: 0px;
	border-color: hsla(210, 96%, 45%, 50%);
	box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02),
		0 0 0 3px hsla(210, 96%, 45%, 25%), 0 1px 1px 0 rgba(0, 0, 0, 0.08);
}

#card-errors {
	color: #ff4040;
	margin-top: 10px;
	font-size: 14px;
}

#submit {
	background-color: #8a2be2;
	color: #ffffff;
	border: none;
	border-radius: 5px;
	padding: 12px 20px;
	font-size: 16px;
	cursor: pointer;
	transition: background-color 0.3s ease;
	width: 100%;
	margin-top: 20px;
}

#submit:hover {
	background-color: #9a4aff;
}

@media only screen and (max-width: 950px) {

	.payment-container {
		gap: 4rem;
	}
	.text-container,
	form {
		width: 100%;
	}
}
