/* fonts */
@import url("https://fonts.googleapis.com/css2?family=Courgette&display=swap");

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
	scroll-behavior: smooth;
}

/* custom scroll bar */
::-webkit-scrollbar {
	width: 10px;
}

::-webkit-scrollbar-track {
	background: black;
}

::-webkit-scrollbar-thumb {
	background: #4782ef;
	border-radius: 30rem;
}

::-webkit-scrollbar-thumb:hover {
	background: #4782ef;
}

.main-content {
	position: relative;
	background-color: rgb(21, 0, 21);
	height: auto;
	min-height: 90.2vh;
	font-family: "Arial", sans-serif;
	padding: 20px 20px 4rem;
}

.container {
	color: rgb(255, 255, 255);
	padding-bottom: 5rem;
	padding-top: 5rem;
}
