  nav {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background-color: rgb(111, 0, 111);
    border-bottom:double;
    border-color: orangered;
    border-width: 5px;
    font-family: "Arial", sans-serif;
    height: 10vh;
  }

  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .logo img {
    width: 3em;
    height: auto;
  }
  
 .logo-name {
    color: rgb(255, 255, 255);
    font-size: 4rem;
    padding-left: 2rem;
    background: #f8f8f8;
    -webkit-background-clip: text;
    background-clip: text;
  }
  
  .logo a {
    text-decoration: none;
    display: flex;
    align-items: center;
  }


  /* Mixx in the title */

.mixx {
  font-family: "Courgette", cursive;
  font-size: 4rem;
  background: #6233f3;
  background: -moz-linear-gradient(left, #6233f3 0%, #2fafe7 50%, #19f5e0 100%);
  background: -webkit-linear-gradient(
    left,
    #6233f3 0%,
    #2fafe7 50%,
    #19f5e0 100%
  );
  background: linear-gradient(to right, #6233f3 0%, #2fafe7 50%, #19f5e0 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}