.alert-container {
  max-width: 300px;
  display: flex;
  justify-content: center;
  padding: 1.5rem 1rem;
  position: fixed;
  top: 120px;
  right: 0;
  /* left: 0; */
  margin-inline: auto;
  border-radius: 8px;
  border: none;
  transform: translateX(-40px);
  color: #ffffff;
  transition: transform 1s ease-out; /* Smooth transition */
  z-index: 1000; /* Ensure it's above other content */
}

.contain p {
  font-size: 16px;
}

/* .alert-container.show {
  transform: translateY(80px); 
} */