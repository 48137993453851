.inner-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-height: 75vh;
	padding: 2rem;
	box-sizing: border-box;
  text-align: center;
}

.inner-container p {
	color: #ffffff;
}


.loader {
	width: 30px !important;
  height: 30px !important;
}

.loader:before {
	width: 30px !important;
	height: 30px !important;
}

.loader:after {
	width: 30px !important;
	  height: 30px !important;
}