.mixx-title {
	font-size: 3rem;
	font-weight: bold;
	text-align: center;
}


.tab-container {
	width: 90%;
	max-width: 600px;
	display: flex;
	justify-content: center;
	text-align: center;
	margin: 0 auto 2rem;
	border-radius: 10px;
	box-shadow: 0 0px 5px rgb(255, 255, 255);
}

.tab-container button {
	width: 50%;
	padding: 1rem;
	font-size: 18px;
	color: #ffffff;
	border: none;
	cursor: pointer;
}

.button-active {
	background: rgb(255, 255, 255);
}

.button-inactive {
	background: transparent;
}


/* Mixx Container Content */

.upload-container {
	background-color: #ffffff19;
	border-radius: 10px;
	box-shadow: 0 0px 5px rgb(255, 255, 255);
	padding: 20px;
	width: 90%;
	max-width: 600px;
	margin: 20px auto;
}

.upload-header {
	color: rgb(255, 255, 255);
	text-shadow: 3px 2px 0px rgb(111, 0, 111);
	text-align: center;
	margin-bottom: 30px;
	font-size: 2em;
}

.upload-form {
	width: 100%;
}

.file-upload {
	margin-bottom: 20px;
	position: relative;
}

.file-upload label {
	display: block;
	color: rgb(255, 255, 255);
	margin-bottom: 5px;
	font-weight: bold;
	font-size: 1.2em;
	/* text-align: center; */
}

.file-upload input[type="file"] {
	display: none;
}

.custom-file-button {
	border-bottom: 4px double #ff9966;
	display: block;
	padding: 6px 12px;
	cursor: pointer;
	background-color: #ff000000;
	color: white;
	border-radius: 0 25px;
	font-size: 0.85em !important;
	font-weight: bold;
	transition: background-color 0.5s ease-in-out;
	text-align: center;
	word-wrap: break-word;
}

.custom-file-button:hover {
	background-color: #ff996633;
	color: white;
	border-radius: 0px 50px;
}

.button-container {
	display: flex;
	justify-content: center; /* Center horizontally */
}

.mixx-button {
	--glow-color: rgb(217, 176, 255);
	--glow-spread-color: rgba(191, 123, 255, 0.781);
	--enhanced-glow-color: rgb(231, 206, 255);
	--btn-color: rgb(100, 61, 136);
	border: 0.25em solid var(--glow-color);
	padding: 1em 3em;
	color: var(--glow-color);
	font-size: 15px;
	font-weight: bold;
	background-color: var(--btn-color);
	border-radius: 1em;
	outline: none;
	box-shadow: 0 0 1em 0.25em var(--glow-color),
		0 0 4em 1em var(--glow-spread-color),
		inset 0 0 0.75em 0.25em var(--glow-color);
	text-shadow: 0 0 0.5em var(--glow-color);
	position: relative;
	transition: all 0.3s;
}

.mixx-button::after {
	pointer-events: none;
	content: "";
	position: absolute;
	top: 120%;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: var(--glow-spread-color);
	filter: blur(2em);
	opacity: 0.7;
	transform: perspective(1.5em) rotateX(35deg) scale(1, 0.6);
}

.mixx-button:hover {
	color: var(--btn-color);
	background-color: var(--glow-color);
	box-shadow: 0 0 1em 0.25em var(--glow-color),
		0 0 4em 2em var(--glow-spread-color),
		inset 0 0 0.75em 0.25em var(--glow-color);
	cursor: pointer;
}

.mixx-button:active {
	box-shadow: 0 0 0.6em 0.25em var(--glow-color),
		0 0 2.5em 2em var(--glow-spread-color),
		inset 0 0 0.5em 0.25em var(--glow-color);
}
